/* eslint-disable no-unused-vars */
<template>
    <div class="container">
        <div class="header">
            <div class="header-title">
                <h1>睿腾资讯</h1>
                <p>RUITENG ACTIVITIES</p>
            </div>
        </div>
        <div class="body">
            <div class="body-header">
                <div class="left-banner">
                    <el-carousel height="1.984375rem" :interval="5000" arrow="never" ref="carousel" @change="onCarouselChange">
                        <el-carousel-item>
                            <img src="../../../static/img/news/news-body-banner-01.png" alt="">
                        </el-carousel-item>
                        <el-carousel-item>
                            <img src="../../../static/img/news/news-body-banner-02.png" alt="">
                        </el-carousel-item>
                        <el-carousel-item>
                            <img src="../../../static/img/news/news-body-banner-03.png" alt="">
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="right-con">
                    <dl v-for="(item, index) in bannerDataArr" :key="index" :class="index===imgActiveIndex ? 'is-active' : ''" @mouseover="onCarouselChange2(index)">
                        <dt>
                            <h3>{{ item.timeH3 }}</h3>
                            <p>{{ item.timeP }}</p>
                        </dt>
                        <dd>
                            <a :href="item.link" :target="item.link != 'javascript:;' ? '_blank' : ''">
                                <h3>{{ item.h3 }}</h3>
                                <p>{{ item.p }}</p>
                            </a>
                        </dd>
                    </dl>
                </div>
            </div>
            <div class="body-content">
                <dl v-for="(item, index) in databox" :key="index">
                    <a :href="item.link" :target="blank" @click="linkPage(item)">
                        <dt>
                            <img :src="item.img" />
                        </dt>
                        <dd>
                            <div class="title clearfix">
                                <h3>{{ item.title }}</h3>
                                <span>{{ item.time }}</span>
                            </div>
                            <p>{{ item.msg }}</p>
                        </dd>
                    </a>
                </dl>
            </div>
            <div class="page">
                <el-pagination
                    @current-change="handleCurrentChange"
                    layout="prev, pager, next"
                    :page-size="pagesize"
                    :total="total">
                </el-pagination>
            </div>
        </div>
        <div class="popup" v-if="showPopup">
            <div class="popup-container">
                <div class="popup-title">
                    <img class="close" src="../../../static/img/news/close.png" alt="" @click="closePopup">
                </div>
                <div class="popup-con">
                    <h2>{{ dataArr[id].title }}</h2>
                    <span>发布时间 {{ dataArr[id].time }}</span>
                    <div class="text">
                        <p v-html="dataArr[id].msg"></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="black-bg" :style="{height: bgHeight + 'px'}" v-if="showPopup"></div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            id: null, //文章id
            imgActiveIndex: 0, //获取第几个轮播图
            pagesize: 5, //每页多少条数据
            currentPage: 1, //当前第几页
            total: 0, //数据总数
             //轮播图数据数组
            bannerDataArr: [
                {
                    link: 'https://www.sensetime.com/cn/news-detail/55878?categoryId=72',
                    timeH3: '21',
                    timeP: '2021/01',
                    h3: '商汤科技再摘一项权威标准认证：隐私信息保护建设获国际认可',
                    p: '2021年1月21日--全球领先的人工智能平台公司商汤科技SenseTime在隐私保护方面再...'
                },
                {
                    link: 'https://www.sensetime.com/cn/news-detail/55146?categoryId=72',
                    timeH3: '23',
                    timeP: '2020/11',
                    h3: '商汤科技担任IEEE移动设备增强现实标准工作组主席单位',
                    p: '近日，拥有近300家成员单位、汇聚中国AR半壁江山的中国增强现实核心技术产业联盟...'
                },
                {
                    link: 'https://www.sensetime.com/cn/news-detail/629?categoryId=72',
                    timeH3: '22',
                    timeP: '2019/12',
                    h3: '商汤科技原创AI技术再出海，AI Cloud加持泰国地产行业发展',
                    p: '近日，全球领先的人工智能平台公司商汤科技与泰国SKY ICT Public Company Limited...'
                }
            ],
            //文章列表数据数组
            dataArr:[
                {
                    id: 0,
                    link: 'https://www.sensetime.com/cn/news-detail/663?categoryId=72',
                    img: require('../../../static/img/news/news-img-01.png'),
                    title: '商汤科技与中央广播电视总台达成合作，促进视听广播智能化革新',
                    time: '2019-12-05',
                    msg: '12月6日，上海，全球领先的人工智能平台公司商汤科技与中央广播电视总台“超高清视音频制播呈现国家重点实验室”签署合作协议，共同加速探索人工智能在媒体生产...'
                },
                {
                    id: 1,
                    link: 'https://www.sensetime.com/cn/news-detail/712?categoryId=72',
                    img: require('../../../static/img/news/news-img-02.png'),
                    title: '用AI打造有爱的智慧社区',
                    time: '2019-11-24',
                    msg: '几百台搭载着商汤科技SenseGate睿行人脸识别一体机的全新闸机系统，落地四川省21个地级市（自治州）的多个智慧平安小区，几十万居民可以不用停留、无需刷门禁卡，就能出入“自家小区”了...'
                },
                {
                    id: 2,
                    link: 'https://www.sensetime.com/cn/news-detail/54569?categoryId=72',
                    img: require('../../../static/img/news/news-img-03.png'),
                    title: '商汤科技、阿里巴巴及香港科技园联手成立AI实验室 促进香港国际创新科技中心建设',
                    time: '2018-05-21',
                    msg: '今日，全球最具价值的人工智能创新企业商汤科技、阿里巴巴集团（纽交所代号：BABA）及香港科技园公司宣布合作成立“香港人工智能实验室”。这是继5月14日中央宣布系列政策，推动香港加强与内地的科技合作，创建国际创科中心后的第一个重大举措...'
                },
                {
                    id: 3,
                    link: 'https://www.sensetime.com/cn/news-detail/54586?categoryId=72',
                    img: require('../../../static/img/news/news-img-04.png'),
                    title: '中国人工智能要“郑和下西洋”',
                    time: '2017-12-10',
                    msg: '12月9-10日，在北京举办的《中国企业家》“2017中国企业领袖年会”上，商汤科技SenseKeeper人脸识别闸机，为一百多位商界大咖提供刷脸签到服务，其中包括李开复、李东生、董明珠等...'
                },
                {
                    id: 4,
                    link: 'https://www.sensetime.com/cn/news-detail/54588?categoryId=72',
                    img: require('../../../static/img/news/news-img-05.png'),
                    title: '商汤科技获Qualcomm 战略投资 中国原创AI 技术开启全球征程',
                    time: '2017-11-15',
                    msg: '11 月15 日，中国领先新锐AI 企业商汤科技宣布获得全球通信巨头Qualcomm Incorporated （NASDAQ：QCOM）的战略投资。此次投资意味着原创AI 算法将成为推动手机及终端智能化发展的一个重要因素...'
                },
            ],
            //文章详情数据数组
            newsArr: [
                {
                    img: [
                        require('../../../static/img/news/news-img-01.png'),
                    ],
                    title: '腾讯云与美的IoT合作共建智能家居新生态',
                    time: '2020-05-29',
                    msg: '<p style="margin-bottom:.104167rem">5月28日，腾讯云与美的IoT达成战略合作，双方将进一步深化在AIoT领域的合作，围绕AIoT物联网智能家居解决方案展开AI、大数据、内容服务、智能产品等层面合作，推动家电智能化和智慧生活场景服务落地，加快AIoT新兴产业发展。</p>' + 
                        '<p style="margin-bottom:.104167rem">随着云计算、AI、5G等技术的快速落地应用，以智能冰箱、智能空调为代表的各类智能家电进入更多家庭，给日常生活、休闲、娱乐带来更多便利的同时也有效提升了家居生活的品质。然而现有大多数智能家电产品仍存在产品之间互联不足、应用场景分散、操作过于复杂、学习使用成本过高等诸多问题，制约了智能家居的进一步普及，也降低了部分消费者对智能家电的使用体验和消费热情。</p>' + 
                        '<p style="margin-bottom:.104167rem">为了充分解决制约智能家电产品发展的痛点，美的IoT将与腾讯云在智能化、数字化、云服务、IoT平台互通、内容服务、绿色智能数据中心等核心领域展开深度合作，优化AIoT智能家电产品用户体验，加强智能家居生态建设。</p>' + 
                        '<p>在AIoT终端产品方面，双方将共同开发语音AI、图像AI技术创新应用，利用腾讯云技术和平台优势，为美的空调、冰箱等智能家电产品提供语音控制、AI识别等方面的服务，从而带给消费者更加高效、自然的操作体验以及丰富的应用场景。</p>'
                },
            ],
            showPopup: false, //详情弹窗
            bgHeight: 0, //遮罩层的高
            blank: ''
        }
    },
    mounted() {
        this.total = this.dataArr.length
        this.bgHeight = document.body.clientHeight
    },
    computed: {
        // 将计算的分页数据缓存起来
        databox() {
            return this.dataArr.slice(
                (this.currentPage - 1) * this.pagesize,
                this.currentPage * this.pagesize
            )
        }
    },
    methods: {
        // 左边轮播图change事件
        onCarouselChange(index) {
            this.imgActiveIndex = index
        },
        // 右边资讯change事件
        onCarouselChange2(index) {
            this.$refs.carousel.setActiveItem(index)
        },
        // 分页方法
        handleCurrentChange(val) {
            this.currentPage = val
        },
        // 点击资讯跳转页面
        linkPage(data) {
            this.id = data.id
            if (data.link == 'javascript:;') {
                this.blank = ''
                this.showPopup = true
            } else {
                this.blank = '_blank'
            }
        },
        // 关闭弹窗
        closePopup() {
            this.showPopup = false
        }
    }
}
</script>
<style lang="scss" scoped>
.container{
    width: 100%;
    min-width: 1200px;
    .header{
        width: 100%;
        height: 1.786458rem;
        position: relative;
        background: url(../../../static/img/news/head-banner.png) no-repeat center;
        background-size: cover;
        overflow: hidden;
        .header-title{
            font-size: 0.25rem;
            font-weight: 400;
            color: #FFFFFF;
            text-align: center;
            margin-top: .3125rem;
            letter-spacing: 3px;
            h1{
                font-size: .145833rem;
            }
            p{
                font-size: .104167rem;
                margin-top: .052083rem;
            }
        }
    }
    .body{
        width: 6.25rem;
        margin: -.755208rem auto .3125rem;
        padding: .3125rem 0 .15625rem;
        background-color:#fff;
        border-radius: 4px;
        position: relative;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
        .body-header{
            padding: 0 .15625rem;
            .left-banner,.right-con{
                display: inline-block;
                vertical-align: middle;
            }
            .left-banner{
                width: 2.739583rem;
                border-radius: 4px;
                overflow: hidden;
                background-color: #000;
                ::v-deep .el-carousel__indicator--horizontal{
                    padding: 12px 5px;
                    .el-carousel__button{
                        width: .03125rem;
                        height: .03125rem;
                        border-radius: 50%;
                        opacity: 1;
                    }
                }
                ::v-deep .el-carousel__indicator.is-active button {
                    background-color: #4285F4;
                }
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .right-con{
                margin-left: .15625rem;
                dl{
                    padding: .098958rem .104167rem;
                    border-bottom: 1px solid #eee;
                    &:last-child{
                        margin-bottom: 0;
                    }
                    &:hover{
                        background-color: #4285F4;
                        dd{
                            a{
                                color: #fff;
                                p{
                                    color: #fff;
                                }
                            }
                        }
                    }
                    &.is-active{
                        background-color: #4285F4;
                        dd{
                            a{
                                color: #fff;
                                p{
                                    color: #fff;
                                }
                            }
                        }
                    }
                    dt,dd{
                        display: inline-block;
                        vertical-align: middle;
                    }
                    dt{
                        width: .395833rem;
                        height: .375rem;
                        background-color: #F5F5F5;
                        text-align: center;
                        padding: .036458rem 0;
                        h3{
                            font-size: .135417rem;
                        }
                        p{
                            font-size: .072917rem;
                        }
                    }
                    dd{
                        width: 2.333333rem;
                        margin-left: .104167rem;
                        h3{
                            font-size: .083333rem;
                            margin-bottom: .083333rem;
                        }
                        p{
                            line-height: .135417rem;
                            font-size: .072917rem;
                            color: #666;
                        }
                    }
                }
            }
        }
        .body-content{
            margin-top: .208333rem;
            dl{
                padding: .104167rem .15625rem;
                border-bottom: 1px dotted #ddd;
                &:hover{
                    background-color: #4285F4;
                    dd{
                        .title{
                            h3,span{
                                color: #fff;
                            }
                        }
                        p{
                            color: #fff;
                        }
                    }
                }
                a{
                    display: block;
                }
                dt,dd{
                    display: inline-block;
                    vertical-align: top;
                }
                dt{
                    width: 1.015625rem;
                    height: .619792rem;
                    border-radius: 4px;
                    overflow: hidden;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                dd{
                    width: 4.817708rem;
                    margin-left: .104167rem;
                    .title{
                        h3{
                            font-size: .083333rem;
                            float: left;
                        }
                        span{
                            float: right;
                            font-size: .072917rem;
                            color: #999;
                            margin-top: .010417rem;
                        }
                    }
                    p{
                        font-size: .072917rem;
                        color: #666;
                        margin-top: .083333rem;
                    }
                }
            }
        }
        .page{
            text-align: center;
            margin-top: .15625rem;
            ::v-deep .el-pager{
                li{
                    min-width: .15625rem;
                    border: 1px solid #ddd;
                    border-radius: 4px;
                    margin-right: .052083rem;
                    &.active{
                        background-color: #448AFF;
                        color: #fff;
                        border: 0;
                    }
                }
            }
            ::v-deep .btn-prev,::v-deep .btn-next{
                min-width: .15625rem;
                border: 1px solid #ddd;
                border-radius: 4px;
                padding: 0;
                text-align: center;
            }
            ::v-deep .btn-prev{
                margin-right: .052083rem;
            }
        }
    }
    .popup{
        width: 5.208333rem;
        position: fixed;
        top: 13%;
        left: 50%;
        margin-left: -2.604167rem;
        background-color: #fff;
        z-index: 10;
        .popup-title{
            width: 100%;
            height: 1.145833rem;
            background: url(../../../static/img/news/popup-title-bg.png) no-repeat center;
            background-size: cover;
            .close{
                width: .083333rem;
                height: .083333rem;
                position: absolute;
                top: .15625rem;
                right: .15625rem;
                cursor: pointer;
            }
        }
        .popup-con{
            padding: .15625rem .15625rem .0625rem;
            h2,span{
                text-align: center;
            }
            h2{
                font-size: .104167rem;
            }
            span{
                display: block;
                font-size: .072917rem;
                color: #666;
                margin: .052083rem 0 .15625rem;
            }
            .text{
                p{
                    font-size: .072917rem;
                    line-height: .135417rem;
                    text-indent: 2em;
                    color: #666;
                    margin-bottom:.104167rem;
                }
            }
        }
    }
    .black-bg{
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .5);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 8;
    }
}
</style>